<template>
  <v-row class="campaigns-new">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/campaigns" class="white--text text-uppercase">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon> New SMS Campaign
          </router-link>
        </div>
        <div class="d-sm-block d-none white--text">Step {{ progress / 50 }}/2</div>
      </div>
      <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15 pa-sm-10 px-4 py-5">
        <v-progress-linear style="position: absolute; top: 0; left: 0" height="8" color="success" :value="progress"></v-progress-linear>
        <v-window v-model="progress">
          <v-window-item :value="50">
            <div class="card-content mt-1">
              <v-text-field v-model="name" label="Compaign name" :error-messages="nameErrors" filled outlined dense required color="input">
              </v-text-field>
              <v-text-field
                v-model="sender"
                label="Sender ID"
                :error-messages="senderErrors"
                filled
                outlined
                dense
                required
                color="input"
                readonly
              >
                <template v-slot:append>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-help-circle</v-icon>
                    </template>
                    <div class="tooltip-width white--text">Max length is 11 Latin letters or numbers</div>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-select
                v-model="recipients"
                :items="selectItems"
                label="Recipients"
                :hint="
                  recipients == 'Manual'
                    ? `With 'Manual' recipients option you can provide a plain list of phone numbers`
                    : `With 'Groups' recipients option your message will be sent to all contacts for the selected group(s)`
                "
                persistent-hint
                filled
                outlined
                dense
                color="input"
              ></v-select>
              <v-select
                v-if="recipients == 'Group'"
                v-model="groupSelect"
                :items="groupsList"
                label="Select a group"
                item-text="name"
                item-value="id"
                persistent-hint
                filled
                outlined
                dense
                color="input"
                @change="msgHelper"
              ></v-select>
              <div class="card">
                <v-textarea
                  v-if="recipients != 'Group'"
                  v-model="numbers"
                  :error-messages="numbersErrors"
                  label="Numbers"
                  :placeholder="
                    `Type or paste phone numbers, one number per line. You can add no more that ${constraints.phones_max_number} contact`
                  "
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  rows="5"
                  auto-grow
                  counter
                >
                  <template v-slot:append>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle</v-icon>
                      </template>
                      <div class="tooltip-width white--text">Phone numbers must be in the international E.164 format</div>
                    </v-tooltip>
                  </template>
                  <template v-slot:counter>
                    <div class="text-caption">Recipients: {{ phoneCount }}/{{ constraints.phones_max_number }}</div>
                  </template>
                </v-textarea>
                <div class="error--text text-caption mt-n8 ml-3 error-msg" v-if="error == 'phones__invalid' && this.$v.numbers.required">
                  Phone number is not valid.
                  <v-btn
                    small
                    height="18"
                    class="text-decoration-underline ml-n2 mt-n1"
                    v-if="phoneError.length > 1"
                    text
                    color="error"
                    @click="errorsModal = true"
                  >
                    More
                  </v-btn>
                </div>
              </div>
              <v-textarea
                v-model="msg"
                label="Message text"
                :error-messages="msgErrors"
                filled
                outlined
                dense
                required
                color="input"
                rows="5"
                auto-grow
                counter
                @input="updateCalculator"
              >
                <template v-slot:append>
                  <div class="d-flex">
                    <v-menu offset-y v-if="helperMsg.length">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text fab small color="input">
                          <v-icon>mdi-xml</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="d-flex flex-column">
                        <div class="pa-2">List of variables</div>
                        <v-btn
                          v-for="item in helperMsg"
                          :key="item.id"
                          large
                          text
                          color="input"
                          class="text-body-2 text-capitalize"
                          @click="fullMsg(item.name)"
                        >
                          {{ item.title }}
                        </v-btn>
                      </v-card>
                    </v-menu>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle</v-icon>
                      </template>
                      <div class="tooltip-width white--text">
                        Both Latin and Unicode characters are allowed. The message can not be longer than 6 SMS
                      </div>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:counter>
                  <div class="text-caption">{{ msg.length || 0 }} symbols, {{ msgCount }} SMS</div>
                </template>
              </v-textarea>
              <div v-if="recipients == 'Group'" class="d-flex align-start">
                <v-icon class="input--text mr-2">mdi-alert-octagon</v-icon>
                <div class="secondary--text">
                  The final message length and SMS count for each message depend on the inserted variable(s) value length. We compute the
                  min and max number based on selected group(s)
                </div>
              </div>
              <v-checkbox v-model="opening" color="success">
                <template v-slot:label>
                  <div class="d-flex flex-column">
                    <div class="text-h6">Track link(s) opening</div>
                    <div class="text-body-1 secondary--text">
                      If tracking enabled, the original link(s) from the message text will be replaced with service short links
                    </div>
                  </div>
                </template>
              </v-checkbox>
              <v-radio-group v-model="radios" mandatory>
                <template v-slot:label>
                  <div class="text-h6">Send SMS</div>
                </template>
                <v-radio label="Immediately" value="immediately" color="success"></v-radio>
                <v-radio label="Schedule sending" value="scheduled" color="success"></v-radio>
              </v-radio-group>
              <div v-if="radios == 'scheduled'">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-menu v-model="modal" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          :error-messages="dataErrors"
                          label="Date"
                          append-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="new Date().toISOString().substr(0, 10)"
                        color="primary"
                        v-model="date"
                        @input="modal = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-menu
                      ref="menu"
                      v-model="modal2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="time"
                          :error-messages="timeErrors"
                          label="Time"
                          append-icon="mdi-clock-time-four-outline"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker v-if="modal2" v-model="time" full-width @click:minute="$refs.menu.save(time)"></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <div class="error--text text-caption ml-3 error-msg" v-if="error == 'send_after__in_past'">
                  Scheduled date can not be in past
                </div>
              </div>
            </div>
            <v-card-actions class="justify-center">
              <v-btn width="300" large color="secondary" @click="send(true)">Next</v-btn>
            </v-card-actions>
          </v-window-item>
          <v-window-item :value="100">
            <div class="d-flex flex-wrap justify-space-between">
              <div v-for="item in title" :key="item.text" class="mx-md-0 mx-3">
                <div class="text-h5">{{ item.title }}</div>
                <v-divider width="25" class="pt-1" :class="item.color"></v-divider>
                <div class="opasity--text">{{ item.text }}</div>
              </div>
            </div>
            <v-card elevation="3" class="my-10 mx-1 pt-3 pb-5 px-4">
              <div class="success--text">Message Preview:</div>
              <div class="opasity--text mt-4">{{ data.message_text }}</div>
            </v-card>
            <v-data-table hide-default-footer :headers="headers" :items="data.routes"></v-data-table>
            <div class="text-right text-uppercase">
              Total price: <span class="text-h5"> {{ data.total }}</span>
            </div>
            <v-card-actions class="justify-center card-two-btn">
              <v-btn text large color="secondary" @click="progress = 50"><v-icon left>mdi-arrow-left</v-icon> Back</v-btn>
              <v-btn width="300" large color="primary" @click="send(false)">Sent</v-btn>
            </v-card-actions>
          </v-window-item>
        </v-window>
      </v-card>
      <v-dialog v-model="errorsModal" max-width="820">
        <v-card>
          <v-divider class="py-1 error"></v-divider>
          <div class="pa-10 pt-8">
            <div class="text-h4 font-weight-bold">Phone numbers are invalid</div>
            <div class="opasity--text mt-2">
              Treat your recovery codes with the same level of attention as you would your password! We recommend saving them with a
              password manager such as Lastpass, 1Password, or Keeper.
            </div>
            <v-virtual-scroll class="mt-5 mx-10" :items="phoneError" :item-height="40" height="392">
              <template v-slot:default="{ item }">
                <div class="text-h6 clip">{{ item }}</div>
              </template>
            </v-virtual-scroll>
            <v-divider class="my-5"></v-divider>
            <div class="d-flex justify-space-between">
              <v-btn width="150" large text color="secondary" @click="errorsModal = !errorsModal">Close</v-btn>
              <v-btn width="150" large class="input--text mr-6" @click="copy">
                <v-icon left>mdi-file-multiple</v-icon>
                Copy
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import SegmentedMessage from '@/segmentsCalculator.js';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    name: { required },
    sender: { required },
    numbers: { required },
    msg: { required },
    date: { required },
    time: { required },
  },
  data() {
    return {
      progress: 50,
      modal: false,
      modal2: false,
      errorsModal: false,
      time: new Date().toTimeString().substr(0, 5),
      date: new Date().toISOString().substr(0, 10),
      name: null,
      sender: 'Info',
      recipients: 'Manual',
      selectItems: ['Group', 'Manual'],
      groupSelect: '',
      numbers: '',
      msg: '',
      msgCount: 1,
      opening: true,
      radios: 'immediately',
      title: [
        { title: '', text: 'Sender', color: 'success' },
        { title: '', text: 'Messages count', color: 'input' },
        { title: '', text: 'SMS per message', color: 'orange' },
        { title: '', text: 'SMS count', color: 'primary' },
        { title: '', text: 'Sending time', color: 'input' },
      ],
      headers: [
        { value: 'country', text: 'Сountry', sortable: false },
        { value: 'mcc', text: 'MCC', sortable: false },
        { value: 'mnc', text: 'MNC', sortable: false },
        { value: 'messages_total', text: 'SMS Count', sortable: false },
        { value: 'price', text: 'Price', sortable: false },
        { value: 'total', text: 'Amount', align: 'end', sortable: false },
      ],
      phoneError: [],
      error: null,
    };
  },
  mounted() {
    this.$store.dispatch('getConstraints');
    this.$store.dispatch('getGroupsAll');
  },
  methods: {
    notifi(btn) {
      if (this.error == 'credential__invalid') {
        this.$notify({
          message: 'Provided email or password is not correct. Please check and try again',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'balance__low') {
        this.$notify({ message: 'Sorry, your balance low', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'copy') {
        this.$notify({ message: 'Data copied', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'copyError') {
        this.$notify({ message: 'Sorry, error copied', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    copy() {
      const string = this.phoneError.join('\n');
      const container = document.querySelector('.v-dialog');
      this.$copyText(string, container)
        .then(() => {
          this.notifi('copy');
        })
        .catch(() => {
          this.notifi('copyError');
        });
    },
    fullMsg(type) {
      this.msg = `${this.msg}{{${type}}}`;
    },
    async send(action) {
      this.$v.time.$touch();
      this.$v.date.$touch();
      this.$v.name.$touch();
      this.$v.sender.$touch();
      this.$v.msg.$touch();
      this.recipients != 'Group' ? this.$v.numbers.$touch() : true;
      if (
        (!this.$v.name.$invalid,
        !this.$v.date.$invalid,
        !this.$v.time.$invalid,
        !this.$v.sender.$invalid,
        !this.$v.msg.$invalid,
        this.recipients != 'Group' ? !this.$v.numbers.$invalid : true)
      ) {
        this.error = null;
        this.phoneError = [];
        const fullDate = new Date(this.date).getTime() + this.mutTime(this.time) + new Date().getTimezoneOffset() * 60000;
        const data = new Object();
        data.name = this.name;
        data.targets_type = this.recipients.toLowerCase();
        this.recipients == 'Group' ? (data.group = this.groupSelect) : (data.phones = this.numbers.split('\n'));
        data.sender_id = this.sender;
        data.message_text = this.msg;
        data.send_option = this.radios;
        this.radios == 'scheduled' ? (data.send_after = fullDate) : null;
        data.track_link_opening = this.opening;
        await this.$store
          .dispatch('sentCampaign', { action, data })
          .then(() => {
            this.progress = 100;
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            this.notifi();
            if (e.response.data.phone_error) {
              this.phoneError = e.response.data.phone_error;
            }
          });
      }
    },
    mutTime(hhmm) {
      hhmm = `${hhmm.toString()}:00`;

      var parts = hhmm.split(':');
      var n = parts.length;
      var ms = 0;
      var i;

      for (i = 0; i < parts.length; i++) {
        var part = parseInt(parts[n - 1 - i]);
        if (i === 0) {
          ms += part * 1000;
        } else if (i === 1) {
          ms += part * 6e4;
        } else if (i === 2) {
          ms += part * 36e5;
        }
      }
      return ms;
    },
    async msgHelper() {
      await this.$store.dispatch('getGroupsVars', this.groupSelect);
    },
    updateCalculator() {
      const segmentedMessage = new SegmentedMessage(this.msg, 'auto');
      this.msgCount = segmentedMessage.segments.length;
    },
  },
  watch: {
    data() {
      this.title[0].title = this.data.sender_id;
      this.title[1].title = this.data.messages_total;
      this.title[2].title = this.data.segments;
      this.title[3].title = this.data.segments_total;
      this.title[4].title =
        this.data.send_option.name == 'immediately'
          ? this.data.send_option.title
          : new Date(this.data.send_after).toUTCString().substr(5, 17);
    },
  },
  computed: {
    constraints() {
      return this.$store.getters.constraints;
    },
    data() {
      return this.$store.getters.campaignNew;
    },
    groupsList() {
      return this.$store.getters.groupsAll;
    },
    helperMsg() {
      return this.$store.getters.groupsVars;
    },
    phoneCount() {
      return this.numbers.length ? this.numbers.split('\n').length : 0;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      !this.$v.name.required && errors.push('Compaign name is required');
      this.error == 'name__invalid' && errors.push('Sorry, Compaign name invalid');
      return errors;
    },
    senderErrors() {
      const errors = [];
      if (!this.$v.sender.$dirty) {
        return errors;
      }
      !this.$v.sender.required && errors.push('Sender ID is required');
      this.error == 'sender_id__invalid' && errors.push('Sorry, Sender ID invalid');
      return errors;
    },
    numbersErrors() {
      const errors = [];
      if (!this.$v.numbers.$dirty) {
        return errors;
      }
      !this.$v.numbers.required && errors.push('Phone number is required');
      this.error == 'phones__invalid' && errors.push('');
      return errors;
    },
    msgErrors() {
      const errors = [];
      if (!this.$v.msg.$dirty) {
        return errors;
      }
      !this.$v.msg.required && errors.push('Sender ID is required');
      this.error == 'message_text__invalid' && errors.push('Sorry, message invalid');
      return errors;
    },
    dataErrors() {
      const errors = [];
      if (!this.$v.date.$dirty) {
        return errors;
      }
      !this.$v.date.required && errors.push('Date is required');
      this.error == 'send_after__in_past' && errors.push('');
      return errors;
    },
    timeErrors() {
      const errors = [];
      if (!this.$v.time.$dirty) {
        return errors;
      }
      !this.$v.time.required && errors.push('Tiem is required');
      this.error == 'send_after__in_past' && errors.push('');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setGroupsVars', []);
    this.$store.dispatch('setGroupsAll', []);
    this.$store.dispatch('setConstraints', {});
  },
};
</script>

<style lang="scss">
.campaigns-new {
  .card-content {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  .tooltip-width {
    max-width: 350px;
    width: 100%;
  }
  .card-two-btn {
    position: relative;
  }
  .card-two-btn button:first-child {
    position: absolute;
    left: 0;
  }
  .card {
    position: relative;
  }
  tbody tr {
    height: 52px;
  }
}
</style>
